<template>
  <div style="width: 100%; max-width: 100%;overflow: hidden" :style='isLandscape?{height:"auto"}:{height:"inherit"}'>
    <div style="width: 100%; height: 100%; overflow: hidden;margin: 0 auto">
      <object :data="pdfUrl" type="application/pdf" style="width: 100%; height: 100%; border: none; padding: 0;">
        <el-image :src='imgUrl'></el-image>
      </object>
    </div>
  </div>
</template>

<script setup>
import pdfUrl from './emotion-txt.pdf'
import imgUrl from './emotion-txt.jpg'
import { ref } from 'vue'
const isLandscape = ref(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))

</script>

<style lang="scss"  scoped>

</style>
