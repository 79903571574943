import router from './router'
import store from './store'
// import privateRoutes from './router/routedata'

// 白名单
const whiteList = ['/home', '/tests/products', '/pdf-emotion', '/pdf-emotion-txt']
/**
 * 路由前置守卫
 */
router.beforeEach(async (to, from, next) => {
  // 存在 token ，进入主页
  // if (store.state.user.token) {
  // 快捷访问
  if (store.getters.token) {
    if (to.path === '/login') {
      // 返回到主页
      next('/')
    } else {
      // 判断用户基本资料是否已经获取
      // 如果不存储，那么需要去查询
      if (!store.getters.hasUserInfo) {
        console.log('获取用户信息', store.getters.hasUserInfo)
        await store.dispatch('user/getUserInfo')
      }
      next()
    }
  } else {
    // 没有token的情况下，可以进入白名单
    if (whiteList.indexOf(to.path) > -1) {
      next()
    } else {
      // next('/login')
      next('/home')
    }
  }
})
