<template>
  <div class='container'>
    <h1 style='color: #12264f;text-align: center;margin: 0;padding: 0'>
      全人测评的产品和服务<br>
      覆盖“个体”“组织”以及“国家”层面
    </h1>
    <div class='item-container'>
      <div class='item-div'>
        <!--      情绪扫描-->
        <div>
          <div class='p-item' @click='goItem("情绪扫描")'>
            <el-image :src='p1' class='p-img1'></el-image>
            <div class='p-content p-content1'>
              一项测评双方获益。员工通过匿名方式点击167个情绪表情包，即可分析在自我成长过程中的情绪与情感变化以及个性特征。企业可获得全体员工的情绪状态以及成因，从而制定有针对性的人才激励和管理策略。
            </div>
          </div>
        </div>
        <!--      情商智能-->
        <div>
          <div class='p-item'>
            <el-image :src='p2' class='p-img2'></el-image>
            <div class='p-content p-content2'>
              情商智能包含两个方面：自我管理强的个体具有出色自制力和情绪调节能力，善于反思和从容应对压力；而人际管理强的个体善于察觉他人情绪与需求，具同理心和共情力，能够有效建立和维护人际关系。
            </div>
          </div>
        </div>
        <!--      全人测评-->
        <div>
          <div class='p-item'>
            <el-image :src='p3' class='p-img3'></el-image>
            <div class='p-content p-content3'>
              以“四字词汇”取代传统量表，一套问卷评估用户的人格、兴趣、价值观和胜任力。采用AI算法代替传统计分方式，标记数千项心理与行为特征。报告以短语、指标和文字的多维方式呈现用户的心理全貌。
            </div>
          </div>
        </div>
      </div>
      <div class='item-title'>
        <span>个</span>
        <span>体</span>
      </div>
    </div>
    <div class='item-container'>
      <div class='item-div'>
        <!--      情绪扫描-->
        <div>
          <div class='p-item'>
            <el-image :src='p4' class='p-img4'></el-image>
            <div class='p-content p-content4'>
              该模型帮助管理者从宏观视角了解团队在沟通、协作、冲突处理、员工价值观等方面的整体状况和个性差异，从而为团队组建、空降高管、新员工融入、团队创新协作及企业文化建设提供有价值的决策支持。
            </div>
          </div>
        </div>
        <!--      情商智能-->
        <div>
          <div class='p-item'>
            <el-image :src='p5' class='p-img5'></el-image>
            <div class='p-content p-content5'>
              EVP是企业吸引、激励和留住人才的战略性工具。EVP通过匿名测试，聚焦员工“关注但不满”的因子，帮助企业发现“最紧迫”和“最易改善”的激励因子，从而制定更具针对性的人才吸引、激励和留用策略。
            </div>
          </div>
        </div>
      </div>
      <div class='item-title'>
        <span>组</span>
        <span>织</span>
      </div>
    </div>
<!--    -->
    <div class='item-container'>
      <div class='item-div'>
        <!--      国家文化-->
        <div>
          <div class='p-item'>
            <el-image :src='p6' class='p-img6'></el-image>
            <div class='p-content p-content6'>
              通过8组指标，分析不同国家(地区)之间的文化差异，促进跨文化理解与文化融合。
              基于国家文化差异模型的“跨文化适应力评估”从认知、驱动力、策略和行为四个维度，评估员工的文化敏感度与跨文化适应力。
            </div>
          </div>
        </div>
      </div>
      <div class='item-title'>
        <span>国</span>
        <span>家</span>
        <span>地</span>
        <span>区</span>
      </div>
    </div>
    <br>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { JumpConfig } from '@/utils/config'
const store = useStore()
const loading = ref(false)
const formLabelWidth = '140px'
const isPhone = ref(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))

const p1 = require('../../assets/images/products/1.png')
const p2 = require('../../assets/images/products/2.png')
const p3 = require('../../assets/images/products/3.png')
const p4 = require('../../assets/images/products/4.png')
const p5 = require('../../assets/images/products/5.png')
const p6 = require('../../assets/images/products/6.png')

const goItem = (name) => {
  if (name === '情绪扫描') {
    window.open(JumpConfig.ViewTestProductsUrl + '?testCode=Emotion_EQ', '_blank')
  }
}
</script>

<style lang="scss" scoped>
.container{
  width: 100vw;
  //height: 100vh;
  //overflow: auto;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 20px;
  user-select: none;
  background: rgb(202, 231, 245);
  flex-direction: column;
  gap: 30px;
  .item-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 100px;
    padding-left: 100px;
    .item-title{
      width: 36px;
      height: 100px;
      background: #36338c;
      border: 2px solid #febf00;
      border-radius: 8px;
      color: white;
      display: flex;
      flex-direction: column; /* 列方向排列 */
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
    }
    .item-div{
      display: flex;gap: 30px;flex-direction: column;
      .p-item{
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .p-content{
          width: 493px;
          display: flex;
          align-items: center;
          font-weight: 500;
          color: #000000;
          line-height: 30px;
          font-size: 19px;
          background: white;
          padding: 10px;
          border-radius: 12px;
          margin-top: 3px;
          margin-left: 1px;
          //height: 140px;
          height: 141px;
        }
        .p-content2{
          position: relative;
          left: -1px;
          top: 1px;
         }
        .p-content3{
          position: relative;
          left: -8px;
          top: 1px;
        }
        .p-content4{
          position: relative;
          left: -1px;
          top: 1px;
        }
        .p-content5{
          position: relative;
          left: 1px;
        }
        .p-content6{
          position: relative;
          left: -10px;
          top: 1px;
        }
        .p-img1{
          width: 184px;
          height: 180px;
        }
        .p-img2{
          width: 186px;
          height: 183px;
        }
        .p-img3{
          width: 194px;
          height: 183px;
          position: relative;
          left: -2px;
        }
        .p-img4{
          width: 190px;
          height: 183px;
          position: relative;
          left: -1px;
        }
        .p-img5{
          width: 188px;
          height: 183px;
          position: relative;
          left: -1px;
        }
        .p-img6{
          width: 209px;
          height: 182px;
          position: relative;
          left: -1px;
        }
      }
    }
  }
}

</style>
