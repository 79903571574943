import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import layout from '@/layout'
import layoutApp from '@/layout/index_app'
import store from '@/store'
import { ref, watch } from 'vue'
/**
 * 公开路由表
 */
const publicRoutes = [
  {
    path: '/home',
    component: () => import('@/views/home-noLogiin/index')
  },
  {
    path: '/pdf-emotion',
    component: () => import('@/views/pdf/emotion')
  },
  {
      path: '/pdf-emotion-txt',
      component: () => import('@/views/pdf/emotion-txt')
  },
  {
    path: '/',
    // 注意：带有路径“/”的记录中的组件“默认”是一个不返回 Promise 的函数
    component: layout,
    redirect: '/home',
    meta: {
      title: 'invited',
      icon: 'permission',
      parentId: '2xx'
    },
    children: [
      {
        path: '/home',
        name: 'profile',
        component: () => import('@/views/home/index'),
        meta: {
        }
      },
      {
          path: '/tests/products',
          name: 'products',
          component: () => import('@/views/test-products'),
          meta: {
          }
       },
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/error-page/404')
      },
      {
        path: '/401',
        name: '401',
        component: () => import('@/views/error-page/401')
      }
    ]
  }
]
// 移动端路由表
const routesMB = [
  {
    path: '/home',
    component: () => import('@/views/home-mobile-noLogin/index')
  },
  {
      path: '/pdf-emotion',
      component: () => import('@/views/pdf/emotion')
  },
  {
      path: '/pdf-emotion-txt',
      component: () => import('@/views/pdf/emotion-txt')
  },
  {
    path: '/',
    // 注意：带有路径“/”的记录中的组件“默认”是一个不返回 Promise 的函数
    component: layoutApp,
    redirect: '/home',
    meta: {
      title: 'invited',
      icon: 'permission',
      parentId: '2xx'
    },
    children: [
      {
        path: '/home',
        name: 'profile',
        component: () => import('@/views/home-mobile/index'),
        meta: {
        }
      },
       {
                 path: '/tests/products',
                 name: 'products',
                 component: () => import('@/views/test-products-mobile'),
                 meta: {
                 }
      },
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/error-page/404')
      },
      {
        path: '/401',
        name: '401',
        component: () => import('@/views/error-page/401')
      }
    ]
  }
]

// 声明变量用来接routers
var routes = []
var privateRoutes = []
const screenWidth = ref('')
// 获取页面宽度
screenWidth.value = document.body.clientWidth
if (screenWidth.value != null && screenWidth.value <= 720) {
  console.log('is Mobile')
  routes = routesMB
} else {
  routes = publicRoutes
}
window.onresize = () => {
  screenWidth.value = document.body.clientWidth
}
/**
 * 初始化路由表
 */
export function resetRouter () {
  if (
    store.getters.userInfo &&
    store.getters.userInfo &&
    store.getters.userInfo.menus
  ) {
    const menus = store.getters.userInfo.menus
    menus.forEach(menu => {
      router.removeRoute(menu)
    })
  }
}

const router = createRouter({
  base: '/test',
  history: createWebHashHistory(),
  routes: [...routes]
})

export default router
