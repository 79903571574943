<template>
  <div class='bottom-style'>
    <!-- 底部工信部链接 -->
    <span>Copyright © 2010 上海缘起网络技术有限公司版权所有|
          <a href="https://beian.miit.gov.cn/" target='_blank'>沪ICP备11003447号-1</a>
    </span>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const store = useStore()
const name = ref(store.getters.userInfo.name)
</script>

<style lang="scss" scoped>
.bottom-style{
  width: 100%; height: 130px;
  display: grid;
  justify-content: center;
  align-items: center;
  background: $background-color-primary;
}
</style>
